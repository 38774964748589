/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import bar from '@/components/progress'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/work-orders')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    store.commit('SET_TOKEN', localStorage.getItem('user-token'))
    next()
    return
  }
  next('/login')
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '*',
      name: 'not-found',
      component: () => import('@/views/misc/NotFound.vue')
    },
    {
      path: '/work-orders',
      name: 'work-orders',
      component: () => import('@/views/work-orders/Base.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/work-orders/:id',
      component: () => import('@/views/work-orders/Show.vue'),
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: '',
          name: 'info',
          component: () => import('@/views/work-order/Info.vue')
        },
        {
          path: 'checklist',
          name: 'checklist',
          component: () => import('@/views/work-order/CheckList.vue')
        },
        {
          path: 'product-history',
          name: 'product-history',
          component: () => import('@/views/work-order/ProductHistory.vue')
        },
        {
          path: 'pre-checkout',
          name: 'pre-checkout',
          component: () => import('@/views/work-order/PreCheckout.vue')
        },
        {
          path: 'checkout',
          name: 'checkout',
          component: () => import('@/views/work-order/Checkout.vue')
        },
        {
          path: 'status',
          name: 'status',
          component: () => import('@/views/work-order/Status.vue')
        },
        {
          path: 'repair-details',
          name: 'repair-details',
          component: () => import('@/views/repair-details/Index.vue')
        },
        {
          path: 'appointments',
          name: 'appointments',
          component: () => import('@/views/appointments/Index.vue')
        },
        {
          path: 'parts-request',
          name: 'parts-request',
          component: () => import('@/views/parts-request/Index.vue')
        },
        {
          path: 'parts-request/new',
          name: 'parts-request-new',
          component: () => import('@/views/parts-request/Form.vue')
        },
        {
          path: 'parts-request/:part_id/edit',
          name: 'parts-request-edit',
          component: () => import('@/views/parts-request/Form.vue'),
          meta: { mode: 'edit' }
        },
        {
          path: 'fees-payments',
          name: 'fees-payments',
          component: () => import('@/views/fees-payments/Index.vue')
        },
        {
          path: 'fees-payments/:fee_id/new',
          name: 'new-fee',
          component: () => import('@/views/fees-payments/FeeForm.vue')
        },        {
          path: 'history',
          name: 'history',
          component: () => import('@/views/history/Index.vue')
        },
        {
          path: 'fees-payments/:payment_id/payment-modal',
          name: 'payment-modal',
          component: () => import('@/views/fees-payments/PaymentModal.vue')
        },
        {
          path: 'repair-details/edit',
          name: 'repair-details-edit',
          component: () => import('@/views/repair-details/Form.vue')
        },
      ]
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/contacts/Index.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/companies/Index.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/contacts/:id',
      name: 'contact-info',
      component: () => import('@/views/contacts/Show.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/companies/:id',
      name: 'companies-info',
      component: () => import('@/views/companies/Show.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reports/Index.vue'),
      beforeEnter: ifAuthenticated
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name != 'login' && to.name != 'not-found') bar.start()
  next()
})

export default router