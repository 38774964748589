import { get, put, postFile } from '@/lib/api'

const state = {
  pending: null,
  info: {
    contact: {},
  },
  contact: {},
  paymentInfo: {},
  details: {},
  images: [],
  checkoutMessage: '',
}

const getters = {
  contactName: state => `${state.info.contact.firstname} ${state.info.contact.lastname}`,
  contactAddress: state => {
    let contact = state.info.contact
    if (contact.mailing_address)
      return `${contact.mailing_address} ${contact.mailing_city}, ${contact.mailing_state} ${contact.mailing_zip}`
  },
  serialDateVerified: state => {
    if (state.info.type_repair !== 'non_warranty') {
      return state.details.serial_verified && state.details.date_purchase_verified
    } else {
      return state.details.serial_verified && !!state.details.date_purchase
    }
  },
  modelItemUpdated: state => !!state.details.item_description && !!state.details.model,
  faultDescUpdated: state => !!state.details.fault_description,
  repairDescUpdated: state => !!state.details.repair_description,
  balanceDue: state => state.info.pending <= 0,
}

const actions = {
  async GET_WORK_ORDER({ commit, dispatch }, id) {
    try {
      const order = await get({ url: `work_orders/${id}` })
      commit('SET_WORK_ORDER', order.data)
      dispatch('GET_REPAIR_DETAILS', order.data.id)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error.data.error)
    }
  },
  async GET_REPAIR_DETAILS({ commit }, id) {
    try {
      const details = await get({ url: `work_orders/${id}/repair_details` })
      commit('SET_REPAIR_DETAILS', details.data)
    } catch (error) {
      alert(error.data)
    }
  },
  async UPDATE_REPAIR_DETAILS({ commit, state }, repairDetail) {
    try {
      const details = await put({
        url: `work_orders/${state.info.id}/repair_details/${state.details.id}`,
        data: { repair_detail: repairDetail },
      })
      commit('SET_REPAIR_DETAILS', details.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async UPLOAD_REPAIR_IMAGES({ dispatch, state }, formData) {
    try {
      await postFile({
        url: `work_orders/${state.info.id}/repair_details/upload_image`,
        data: formData,
      })
      await dispatch('GET_REPAIR_DETAILS', state.info.id)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  CHECKOUT_MESSAGE({ commit }, message) {
    commit('SET_CHECKOUT_MESSAGE', message)
  },
}

const mutations = {
  SET_WORK_ORDER: (state, payload) => {
    state.info = payload
    state.pending = payload.pending
    state.contact = payload.contact
    state.paymentInfo = payload.payment_info
  },
  SET_REPAIR_DETAILS: (state, payload) => {
    state.details = payload
  },
  SET_CHECKOUT_MESSAGE: (state, payload) => {
    state.checkoutMessage = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}