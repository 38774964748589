<template>
  <div
    class="loading"
    :style="{
      'opacity': visible ? 1 : 0,
      'width' : visible ? '100%' : '0%',
      'height' : visible ? '100%' : '0%'
    }"
  >
    <div class="sk-three-bounce">
      <div class="sk-child sk-bounce1" />
      <div class="sk-child sk-bounce2" />
      <div class="sk-child sk-bounce3" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
  },
}
</script>

<style scoped>
.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0%;
  height: 0%;
  background-color: #fff;
  z-index: 1500;
}

.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #008AFF;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; 
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; 
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; 
}

@-webkit-keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); 
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); 
  } 
}

@keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); 
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); 
  } 
}
</style>
