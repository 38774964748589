import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './lib/filters'
import Loading from './components/Loading.vue'
import './sw'

// Libs
import 'bootstrap'
import Dayjs from 'vue-dayjs'
import InfiniteLoading from 'vue-infinite-loading'
import VueCurrencyInput from 'vue-currency-input'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/styles/main.scss'

// Loading screen
const loading = Vue.prototype.$loading = new Vue(Loading).$mount()
document.body.appendChild(loading.$el)

// Filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// Set user
if (store.state.auth.token) {
  store.dispatch('SET_USER', true)
}

Vue.use(Dayjs)
Vue.use(VueCurrencyInput)
Vue.use(InfiniteLoading, {
  props: {
    spinner: 'spiral',
  },
  slots: {
    noMore: '',
    noResults: '',
  },
})
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
