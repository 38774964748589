export function phone(phone) {
  if (phone != undefined) {
    return phone.replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
}

export function currency(value) {
  if (!value || value == undefined) return '--'
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  return formatter.format(value)
}

export function humanize(str) {
  if (str != undefined && str != '') {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/(^|\s)\S/g, l => l.toUpperCase())
  } else {
    return '--'
  }
}

export function blank(str) {
  if (!str) {
    return '--'
  } else {
    return str
  }
}