<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { interceptors } from './lib/api'

export default {
  created() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    interceptors((error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('LOGOUT')
        .then(() => this.$router.push('/'))
      }
    })
  },
}
</script>