import { get, post, put, del } from '@/lib/api'

const state = {
  statuses: {},
  categories: [],
  brands: [],
  users: [],
}

const getters = {}

const actions = {
  async FETCH_BASE ({ commit }) {
    try {
      const base = await get({ url: 'base/index' })
      if (base) {
        commit('SET_BASE', base.data)
        //await dispatch('FETCH_GROUP_COUNT')
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async FETCH_CATEGORIES ({ commit }) {
    try {
      const categories = await get({ url: 'product_categories' })
      if (categories) {
        commit('SET_CATEGORIES', categories.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async CREATE_CATEGORY({ commit }, data) {
    try {
      const category = await post({
        url: 'product_categories',
        data: { product_category: data },
      })
      if (category) {
        commit('SET_CATEGORY', category.data)
        return Promise.resolve(category.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async UPDATE_CATEGORY({ commit }, data) {
    try {
      const category = await put({
        url: `product_categories/${data.category.id}`,
        data: { product_category: data.category },
      })
      if (category) {
        commit('SET_UPDATED_CATEGORY', {
          index: data.index,
          category: category.data,
        })
        return Promise.resolve(category.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async DELETE_CATEGORY({ commit }, data) {
    try {
      const category = await del({ url: `product_categories/${data.id}` })
      if (category) {
        commit('UNSET_CATEGORY', data.index)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async FETCH_BRANDS ({ commit }) {
    try {
      const brands = await get({ url: 'brands' })
      if (brands) {
        commit('SET_BRANDS', brands.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async CREATE_BRAND({ commit }, data) {
    try {
      const brand = await post({ 
        url: 'brands',
        data: { brand: data }, 
      })
      if (brand) {
        commit('SET_BRAND', brand.data)
        return Promise.resolve(brand.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async UPDATE_BRAND({ commit }, data) {
    try {
      const brand = await put({
        url: `brands/${data.brand.id}`,
        data: { brand: data.brand },
      })
      if (brand) {
        commit('SET_UPDATED_BRAND', {
          index: data.index, 
          brand: brand.data,
        })
        return Promise.resolve(brand.data)
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async DELETE_BRAND({ commit }, data) {
    try {
      const brand = await del({ url: `brands/${data.id}` })
      if (brand) {
        commit('UNSET_BRAND', data.index)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },
  
  async FETCH_USERS ({ commit })  {
    try {
      const users = await get({ url: 'users' })
      if (users) {
        commit('SET_USERS', users.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },
}

const mutations = {
  SET_BASE (state, base) {
    state.categories = base.categories
    state.brands = base.brands
    state.users = base.users
    state.statuses = base.statuses
  },
  SET_CATEGORIES (state, payload) {
    state.categories = payload
  },
  SET_CATEGORY(state, payload) {
    state.categories = [...state.categories, payload]
  },
  SET_UPDATED_CATEGORY(state, payload) {
    state.categories[payload.index] = { ...payload.category }
  },
  UNSET_CATEGORY(state, payload) {
    state.categories.splice(payload, 1)
  },
  SET_BRANDS (state, payload) {
    state.brands = payload
  },
  SET_BRAND(state, payload) {
    state.brands = [...state.brands, payload]
  },
  SET_UPDATED_BRAND(state, payload) {
    state.brands[payload.index] = { ...payload.brand }
  },
  UNSET_BRAND(state, payload) {
    state.brands.splice(payload, 1)
  },
  SET_USERS (state, payload) {
    state.users = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}